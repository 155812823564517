import { createHashRouter, RouterProvider } from 'react-router-dom'

import { Main } from "./progmatic/main/main";
import { AboutUs } from "./progmatic/about/about"
import { MainVacancy } from "./progmatic/vacancy/vacancy"
import { Technologies } from "./progmatic/technologies/technologies"
import { Contacts } from "./progmatic/contacts/contacts"
import { Partners } from "./progmatic/partners/partners"
import { Sout } from "./progmatic/sout/sout"


const router = createHashRouter([
    {
      path: "/",
      children: [
        {
            index: true,
            Component: Main
        },
        {
            path: 'about',
            Component: AboutUs
            
        },
        // {
        //     path: '/vacancy',
        //     Component: MainVacancy
            
        // },
        {
            path: 'technology-stack',
            Component: Technologies
            
        },
        {
            path: 'partners',
            Component: Partners
            
        },
        {
            path: 'contacts',
            Component: Contacts
        },
        {
            path: 'sout',
            Component: Sout
        },
      ],
    },
  ])

export const App = () => {
    return <RouterProvider router={ router }/>
}

export default App