import React from "react";
import Layout from "../layout";
import PartnerTopImg from "../../common/images/partners/partners-top.svg"
import { Quote } from '../../common/components/quotes'

const PartnersHeader = () => {
    return <div className="w-100 mb-5">
        <div className="h-100 row m-big mw-2000 d-flex-center">
            <div className="col-lg-6">
                <h1 className="my-5 display-1">Наши партнеры и клиенты</h1>
                <div className="display-9 my-big mt-0">
                    <Quote>
                    Если бы партнеров не было, их стоило бы придумать...
                    </Quote>
                </div>
            </div>
            <div className="col-lg-6">
                <img src={PartnerTopImg} alt="" className="w-100"/>
            </div>
        </div>
    </div>
}

const PartnerCard = ({title, img, children, back}) => {
    return <div className="col-lg-4 col-md-6 col-sm-12 p-3">
        <div className={`d-flex flex-column px-3 py-5 partner-card partner-card-${back}`}>
            <div className=" d-flex flex-column h-100">
                <div className="d-flex-center pb-2" style={{height: '40%'}}>
                    <img src={require(`../../common/images/own_partners/${img}.png`)} alt="img" style={{width: '8rem'}}/>
                </div>
                <div style={{height: '60%'}}>
                    <h1 className="display-7 fw-400">{title}</h1>
                    <div className="bg-dark mb-3 about-partner-border-b"/>
                    <div>{children}</div>
                </div>
            </div>
        </div>
    </div>
}

const MainPartners = () => {
    return <div className="m-big">
        <div className="row">
            <PartnerCard title={'ОКБ (Объединенное Кредитное Бюро)'} img={'okb'}>
                <p>разработка решений Кредитного Бюро</p>
            </PartnerCard>
            <PartnerCard title={'Mail.ru/VK'} img={'vk'} back={'back3'}>
                <p>доработки и поддержка облачных решений</p>
            </PartnerCard>
            <PartnerCard title={'OpenSDN'} img={'opensdn'}>
                <p>продолжение развития Contrail/Tungsten Fabric</p>
            </PartnerCard>
            <PartnerCard title={'Juniper Networks Inc'} img={'juniper'}>
                <p>участие в разработке и развитии ряда продуктов и систем компании, в том числе с выходом на opensource</p>
            </PartnerCard>
            <PartnerCard title={'Tungsten Fabric'} img={'tf'} back={'back1'}>
                <p>разработка и поддержка CI/CD, ведение ряда проектов коммьюнити</p>
            </PartnerCard>
            <PartnerCard title={'OpenStack'} img={'openstack'}>
                <p>участие в разработке ряда проектов платформы</p>
            </PartnerCard>
            <PartnerCard title={'AВК-Коммьюникейшнз'} img={'awk'}>
                <p>разработка платформы BPA</p>
            </PartnerCard>
            <PartnerCard title={'Tinkoff'} img={'tinkoff'}>
                <p>работы по развертыванию Contrail</p>
            </PartnerCard>
            <PartnerCard title={'Dell/EMC '} img={'dellemc'} back={'back2'}>
                <p>участие в интеграции ряда продуктов и систем компании во внешние облачные платформы</p>
            </PartnerCard>
            <PartnerCard title={'CloudScaling'} img={'cloudscaling'}>
                <p>участие в разработке продукта компании</p>
            </PartnerCard>
            <PartnerCard title={'Witology'} img={'witology'} back={'back4'}>
                <p>разработка продукта компании и участие в проведении ряда краудсорсинговых проектов</p>
            </PartnerCard>
        </div>
    </div>
}

export const Partners = () => {
    return <Layout>
        <PartnersHeader/>
        <MainPartners/>
    </Layout>
}