import React from "react";
import Layout from "../layout";

const SoutInfo = () => {
    return <div className="about-contacts-container d-flex-center">
        <div className='d-flex-center mw-2000 row' style={{height: '80vh'}}>
            <div className="w-100">
                <h1 className="pb-3">
                    ПРОВЕДЕНИЕ СОУТ
                </h1>
                <div>
                    <p>
                        В соответствии с пунктом 6 статьи 15 Федерального закона от 28.12.2013 № 426-ФЗ «О специальной оценке условий труда» ООО «Прогматик Лаб», 
                        публикует сводные данные о результатах проведения специальной оценки условий труда в части 
                        установления классов (подклассов) условий труда на рабочих местах, а также перечни мероприятий по улучшению условий труда.
                    </p>
                    <div className="py-3">
                        <p>
                            Специальная оценка условий труда (далее – СОУТ) в ООО «Прогматик Лаб» проведена на 16 рабочих местах. Отчет о проведении СОУТ в ООО «Прогматик Лаб» утвержден <strong>01.10.2020</strong>
                        </p>
                        <div className="py-2">
                            <h6 className="sout-href-container">
                                <a className="sout-href" href={require('../../common/sout_files/events_2020.pdf')} download>
                                    Перечень рекомендуемых мероприятий ООО «Прогматик Лаб»
                                </a>
                            </h6>
                            <h6 className="sout-href-container">
                                <a className="sout-href" href={require('../../common/sout_files/statement_2020.pdf')} download>
                                    Сводная ведомость ООО «Прогматик Лаб»
                                </a>
                            </h6>
                        </div>
                    </div>
                    <div className="py-3">
                        <p>
                            Специальная оценка условий труда СОУТ в ООО «Прогматик Лаб» проведена на 8 рабочих местах. Отчет о проведении СОУТ в ООО «Прогматик Лаб» утвержден <strong>07.12.2022</strong>
                        </p>
                        <div className="py-2">
                            <h6 className="sout-href-container">
                                <a className="sout-href" href={require('../../common/sout_files/events_2022.pdf')} download>
                                    Перечень рекомендуемых мероприятий ООО «Прогматик Лаб»
                                </a>
                            </h6>
                            <h6 className="sout-href-container">
                                <a className="sout-href" href={require('../../common/sout_files/statement_2022.pdf')} download>
                                    Сводная ведомость ООО «Прогматик Лаб»
                                </a>
                            </h6>
                        </div>
                    </div>
                    <div className="py-3">
                        <p>
                            Специальная оценка условий труда СОУТ в ООО «Прогматик Лаб» проведена на 11 рабочих местах. Отчет о проведении СОУТ в ООО «Прогматик Лаб» утвержден <strong>21.12.2023</strong>
                        </p>
                        <div className="py-2">
                            <h6 className="sout-href-container">
                                <a className="sout-href" href={require('../../common/sout_files/events_2023.pdf')} download>
                                    Перечень рекомендуемых мероприятий ООО «Прогматик Лаб»
                                </a>
                            </h6>
                            <h6 className="sout-href-container">
                                <a className="sout-href" href={require('../../common/sout_files/statement_2023.pdf')} download>
                                    Сводная ведомость ООО «Прогматик Лаб»
                                </a>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const Sout = () => {
    return <Layout footerNone>
        <SoutInfo/>
    </Layout>
}