import React, { useState, useEffect } from "react"

const PartnerLogo = ({src, style, className}) => {
    const [image, setImage] = useState(`${src}_bw.png`)

    const onHower = (logoName) => {
        setImage(logoName)
    }

    return <img    
        src= {require(`../../common/images/title_logos/${image}`)}
        alt={`${image}`}
        style={style}
        className={className}
        onMouseOver={(e) => onHower(`${src}.png`)}
        onMouseOut={(e) => onHower(`${src}_bw.png`)} 
    />
}

export const MainPartners = () => {

    const logoInformTop = [
        {name: 'scaleio', style: {width: '10%', margin: '21vh 50%'}},
        {name: 'cplusplus', style: {width: '9%', margin: '19vh 69%'}},
        {name: 'cloudscaling', style: {width: '17%', margin: '17vh 9%'}},
        {name: 'java', style: {width: '6%', margin: '9vh 66%'}},
        {name: 'devops', style: {width: '10%', margin: '26vh 17%'}},
        {name: 'ubuntu', style: {width: '10%', margin: '12vh 28%'}},
        {name: 'dellemc', style: {width: '11%', margin: '20vh 36%'}},
        {name: 'linux', style: {width: '14%', margin: '10vh 44%'}},
        {name: 'python', style: {width: '18%', margin: '27vh 38%'}},
        {name: 'windows', style: {width: '15%', margin: '28vh 78%'}},
        {name: 'witology', style: {width: '12%', margin: '22vh 2%'}},
        {name: 'docker', style: {width: '10%', margin: '18vh 82%'}},
        {name: 'helm', style: {width: '7%', margin: '26vh 63%'}},
    ]

    const logoInformBottom = [
        {name: 'juniper', style: {width: '19%', margin: '2vh 0'}},
        {name: 'puppet', style: {width: '10%', margin: '12vh 8%'}},
        {name: 'openstack', style: {width: '18%', margin: '0 24%'}},
        {name: 'juju', style: {width: '10%', margin: '0 45%'}},
        {name: 'csharp', style: {width: '5%', margin: '10vh 25%'}},
        {name: 'kubernetes', style: {width: '16%', margin: '12vh 35%'}},
        {name: 'linux_foundation', style: {width: '8%', margin: '2vh 61%'}},
        {name: 'ansible', style: {width: '8%', margin: '7vh 63%'}},
        {name: 'opnfv', style: {width: '15%', margin: '18vh 55%'}},
        {name: 'redhat', style: {width: '15%', margin: '17vh 25%'}},
        {name: 'go', style: {width: '8%', margin: '20vh 45%'}},
        {name: 'mailru', style: {width: '15%', margin: '1vh 80%'}},
        {name: 'tf', style: {width: '18%', margin: '10vh 75%'}},
    ]

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }
 
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    return <div>
       {windowWidth < 900 
            ? <div className="d-flex-center w-100 mb-5 py-5">
                <img src={require('../../common/images/Progmatic_main.png')} alt="" className="w-100"/>   
            </div>
            : <div className="d-flex-center w-100 mb-5">
                <div style={{minWidth: '50%'}} className="mb-5 d-flex flex-column">
                    <div className="progmatic-images">
                        {
                            logoInformTop.map((logo) => {
                                return <PartnerLogo
                                    src={logo.name} 
                                    style={{...logo.style}} 
                                    className='progmatic-header-logo'
                                />
                            })
                        }
                    </div>
    
                    <div className="progmatic-name d-flex-center my-2">
                        <span className="red1-text">Progmatic&nbsp;</span>Lab
                    </div>

                    <div className="progmatic-images">
                        {
                            logoInformBottom.map((logo) => {
                                return <PartnerLogo
                                    src={logo.name} 
                                    style={{...logo.style}} 
                                    className='progmatic-header-logo'
                                />
                            })
                        }
                    </div>
                </div>
            </div>
       }

    </div>

}

