import React from "react"
import DescriptionImg from '../../common/images/company_description.svg'

const DescriptionIcon = ({num, children}) => {
    return <div className="d-flex py-3">
        <img src={require(`../../common/images/description/description_${num}.png`)} alt="" style={{height: '2rem'}}/>
        <div className="px-3">
            {children}
        </div>
    </div>
}

export const CompanyDescription = () => {
    return <div className="d-flex-center my-big bg-gray">
        <div className="mw-2000 d-flex flex-description px-5">
            <div className="col-lg-10 d-flex flex-column p-5">
                <h1 className="display-5">Создавайте передовые решения с Progmatic Lab</h1>
                <div className="pb-1 my-3 bg-dark" style={{width: '10rem'}}/>
                <em>
                    Бывает такое, что системы плохо работают, новые фичи очень долго добавляются, проблемы накапливаются...
                    <br/>Скореe всего, <strong>мы сможем Вам помочь</strong>. <strong>Попробуйте!</strong>
                </em>
                <div className="pb-1 bg-dark mb-5 mt-3" style={{width: '10rem'}}/>
                <div className="row">
                    <div className="col-lg-6 col-md-12 pr-5">
                        <DescriptionIcon num={1}>
                            <p>Progmatic Lab является <strong>аккредитованной ИТ компанией</strong>, существующей на рынке <strong>с 2007 года</strong> (до 2016 года называлась Progmatic)</p>
                        </DescriptionIcon>
                        <DescriptionIcon num={2}>
                            <p>Наша команда специалистов обладает <strong>высокой экспертизой</strong> в различных областях разработки ПО, что позволяет нам обеспечить высокое <strong>качество реализации и поддержки программных продуктов любой сложности</strong></p>
                        </DescriptionIcon>
                    </div>
                    <div className="col-lg-6 col-md-12 pr-5">
                        <DescriptionIcon num={4}>
                            <p>В компании накоплен большой опыт по рефакторингу и улучшению существующих систем, не удовлетворяющих требуемым показателям</p>
                        </DescriptionIcon>
                        <DescriptionIcon num={3}>
                            <p>Мы предлагаем широкий спектр услуг, начиная с <strong>анализа и подготовки требований</strong>, <strong>проектирования и разработки</strong> и, заканчивая <strong>поддержкой и интеграцией</strong> продукта в производственную среду</p>
                        </DescriptionIcon>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-12 description-img-block">
                <img src={DescriptionImg} alt="" className="description-img"/>
            </div>
        </div>
    </div>
}