import React from 'react'
import { Footer } from './footer/footer'
import { Header } from './header/header'

const Layout = ({children, footerNone}) => {

    return <div className="d-flex flex-column">
        <header className='w-100 position-fixed zindex-sticky'>
            <Header/>
        </header>
        
        <main className="flex-shrink-0 pt-5">
            {children}
        </main>

        {!
            footerNone 
            && <footer>
                <Footer/>
            </footer>
        }
        
    </div>
}

export default Layout