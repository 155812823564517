import React from "react";
import Layout from "../layout";
import Description from '../../common/images/technologies/own-technologies.svg';
import Top from '../../common/images/technologies/technologies_top.svg';
import KeywordsAnalytics from '../../common/images/technologies/keywords_analytics.svg';
import KeywordsSearch from '../../common/images/technologies/keywords_search.svg';
import { Quote } from '../../common/components/quotes'


const TechnologiesHeader = () => {
    return <div className="w-100 my-big">
        <div className="mx-big py-5 mw-2000 d-flex-center">
            <div className="col-lg-6 col-12 h-100 flex-column">
                <h1 className="my-5 display-1">Наш стек <br/>технологий</h1>
                <div>
                    <strong className="display-9">
                        <Quote>
                            Мода - это не только стиль одежды, но еще и один из <em>ключевых определяющих факторов для выбора языков и технологий</em> при создании ПО...
                        </Quote>
                    </strong>
                </div>
            </div>
            <div className="col-lg-6 col-md-0 technologies-top-img">
                <img src={Top} alt="" className="w-100"/>
            </div>
        </div>
    </div>
}

const Keywords = () => {
    return <div className="w-100 p-3 d-flex-between technologies-keywords">
        <img src={KeywordsAnalytics} alt="" className="keywords-search"/>
        <img src={KeywordsSearch} alt="" className="keywords-analytics"/>
    </div>
}

const TechnologiesDescription = () => {
    return <div className="row mw-2000 m-big">
        <div className="col-lg-6">
            <img src={Description} alt="" />
        </div>
        <div className="col-lg-6">
            <h1 className="display-5">Технологии, с которыми мы работаем</h1>
            <div className="py-1 my-4 bg-dark" style={{width: '15rem'}}/>
            <p>Технологии, используемые для создания наших продуктов, определяются, исходя из требований и ограничений системы заказчика</p>
            <p>Достаточно частым паттерном в данный момент является распределенная микросервисная архитектура с высокой доступностью в облачной Linux среде (например, <strong>Kubernetes</strong> или <strong>OpenStack</strong>) и имплементацией на языках <strong>Golang / Python / Bash</strong></p>
            <p>Специалисты компании также имеют экспертизу в разработке на Windows, Linux, с использованием таких языков как <strong>C/C++</strong>, <strong>Java</strong>, <strong>C#</strong> и других</p>
            <p>Для веб разработки преимущественно используется <strong>React</strong></p>
        </div>
        <div className="mt-5 w-100">
            <p className="px-4 py-3 text-center display-9 fw-400">
                <strong>
                    Приносите Ваши технологии, обещаем обращаться с ними так же аккуратно, как и с нашими...
                </strong>
            </p>
        </div>
    </div>
}

export const Technologies = () => {
    return <Layout>
        <div className="technologies-background">
            <TechnologiesHeader/>
            <TechnologiesDescription/>
            <Keywords/>
        </div>
    </Layout>
}