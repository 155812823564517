import { CompanyDescription } from "./company_description"
import { MainPartners } from "./main_partners"
import { SwiperPar } from "./partners"
import { Stacks } from "./stack"

import Layout from "../layout"

export const Main = () => {
    return <Layout>
        <MainPartners/>
        <CompanyDescription/>
        <Stacks/>
        <SwiperPar/>
    </Layout>
}