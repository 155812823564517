import React from "react";

const Logo = ({style, src}) => {

    return <div className='h-100 p-3' style={style}>
        <div className="d-flex-center h-100">
            <img    
                src= {require(`../../common/images/title_logos/${src}_bw.png`)}
                alt={`${src}`}
                style={{ height: 50}}
            />
        </div>
    </div>
}

const LineLogo = () => {
    const bottomColors = [
        'rgba(186, 129, 129, 0.5)', 
        'rgba(216, 180, 180, 0.5)', 
        'rgba(181, 108, 108, 0.7)', 
        'rgba(255, 243, 243, 1)', 
        'rgba(190, 0, 0, 0.5)', 
        'rgba(194, 0, 0, 0.2)',
    ]
    let numColor = 0

    const logoNames = [
        'scaleio', 'cplusplus', 'cloudscaling', 'java', 'devops', 
        'ubuntu', 'linux', 'python','docker', 'helm', 'puppet', 
        'juju', 'csharp', 'kubernetes', 'ansible', 'go', 
    ]

    return <div className='d-flex'>
        {
            logoNames.map((logo) => {
                const currColor = bottomColors[numColor]
                numColor = numColor < bottomColors.length - 1 ? numColor + 1 : 0

                return <Logo style={{backgroundColor: currColor}} src={logo}/>
            })
        }
    </div>
}

export const Stacks = () => {
    return <div className="w-100 my-5" style={{height: 'max-content', overflow: 'hidden'}}>
        <div className='d-flex icons-stack'>
            <LineLogo/>
            <LineLogo/>
        </div>
    </div>
}