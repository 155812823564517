import React from "react"

export const Footer = () => {
    return <div className="mt-5 bg-beige">
        <div className="w-100 px-5">
           <div className="row p-5">
                <div className="col-lg-3"/>
                <div className="col-lg-6 h-100 d-flex-center flex-column justify-content-end text-center">  
                    <div className="pb-3">ООО "Прогматик Лаб" / Progmatic Lab LLc</div>
                    <h5>+7 926 206 56 03</h5>
                    <h6>info@progmaticlab.com</h6>
                    <div>125124, г. Москва, 3-я ул. Ямского Поля, д. 2, корп. 7,  этаж 3, пом. Х,  ком. 56</div>
                </div>
           </div>
        </div>
    </div>

    
}