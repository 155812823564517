import React, {useEffect, useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay, Mousewheel } from 'swiper/modules';

const Logo = ({src}) => {

    return <div className='h-100 p-2'>
        <div className="d-flex-center h-100 p-2">
            <img    
                src= {require(`../../common/images/own_partners/${src}.png`)}
                alt={`${src}`}
                style={{maxHeight: 80, maxWidth: 200}}
            />
        </div>
    </div>
}

export const SwiperPar = () => {
    const logo = ['dellemc', 'tinkoff', 'mailru', 'tf', 'juniper', 'witology', 'awk', 'openstack', 'okb', 'vk', 'opensdn']

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        }
 
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    const countPartners = (width) => {
        if (width < 650) {
            return 1
        }

        if (width < 900) {
            return 3
        }

        if (width < 1200)  {
            return 4
        }

        return 5
    }

    return <div className="w-100 d-flex-center my-big mb-0">
        <div className="mw-2000 w-100">
            <div className="m-5 pb-5">
                <Swiper
                    slidesPerView={countPartners(windowWidth)}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    mousewheel={true}
                    autoplay={{
                        delay: 2500,
                    }}
                    modules={[Pagination, Navigation, Autoplay, Mousewheel]}
                    className="mySwiper"
                >
                    {
                        logo.map((logo) => {
                            return <SwiperSlide>
                                <Logo src={logo} />
                            </SwiperSlide>
                        })
                    }
                <div className="p-3"/>
                </Swiper>
            </div>
        </div>
    </div>
}