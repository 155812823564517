import React from "react";
import Layout from "../layout";
import PhoneImg from '../../common/images/contacts/phone.svg'
import MailImg from '../../common/images/contacts/mail.svg'
import SoutImg from '../../common/images/contacts/sout.svg'

const CompanyContacts = () => {
    return <div className="about-contacts-container d-flex-center flex-column">
        <div className='d-flex-center mw-2000 about-contacts row'>
            <div className="col-lg-7 col-md-6 p-5 py-5">
                <h1 className="display-1 pb-5">Свяжитесь с нами</h1>

                <div className="p-3">
                    <div className="py-2">
                        <h5 className="d-flex contacts-href-container">
                            <img src={PhoneImg} alt="" className="px-2 contacts-href-img"/>
                            <a 
                                href='tel:+79262065603' 
                                className="contacts-href"
                            >
                                +7 926 206 56 03
                            </a>
                        </h5>
                        <p className="d-flex contacts-href-container">
                            <img src={MailImg} alt="" className="px-2 contacts-href-img"/>
                            <a 
                                href="mailto:info@progmaticlab.com" 
                                className="contacts-href d-flex-center"
                            >
                                info@progmaticlab.com
                            </a>
                        </p>
                        <p>125124, г. Москва, 3-я ул. Ямского Поля, д. 2, корп. 7,  этаж 3, пом. Х,  ком. 56</p>
                    </div>
                    <small className='text-secondary'>            
                        <div>ОГРН: 5167746319230</div>
                        <div>ИНН/КПП: 7714957951 / 771401001</div>
                        <div>ОКПО: 05538230</div>
                        <div>ОКТМО: 45334000</div> 
                    </small>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 py-5 d-flex align-items-center google-card-container">
                <a 
                    href='https://maps.app.goo.gl/y67iqbjyAXb6Vv4U6'
                    target="_blank"
                    rel="noreferrer"
                >
                    <img 
                        src={require('../../common/images/contacts/contacts-google-card.png')} 
                        alt=""
                        className="google-card"
                    />
                </a>
            </div>
        </div>
        <p className="d-flex py-1 sout-button-container w-100 mw-2000" style={{ maxHeight: '5vh'}}>
            <div style={{width: 'max-content'}}>
                <a 
                    href="#sout"
                    className="px-3 sout-button d-flex-center"
                >
                    <img src={SoutImg} alt="" className="p-2"/>
                    <strong className="p-2">СОУТ</strong>
                </a>
            </div>
        </p>
    </div>
}

export const Contacts = () => {
    return <Layout footerNone>
        <CompanyContacts/>
    </Layout>
}